<template>
  <a-spin tip="Loading..." :spinning="formLoading" class="page-box">
    <a-form-model
      :loading="formLoading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <p class="sub-title">一、购物券价值规则：</p>
      <a-form-model-item>
        <div class='flex-a-c'>
          <a-form-model-item>
            <div>1、1元钱可兑换</div>
          </a-form-model-item>
          <a-form-model-item prop='param1' style='width:150px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param1"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div>个购物券</div>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class='flex-a-c'>
          <a-form-model-item>
            <div>2、1积分可兑换</div>
          </a-form-model-item>
          <a-form-model-item prop='param2' style='width:150px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param2"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div> 个购物券</div>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <p class="sub-title">二、获得购物券规则：</p>
      <a-form-model-item>
        <div class='flex-a-c'>
          <a-form-model-item>
            <div>1、新用户注册可得</div>
          </a-form-model-item>
          <a-form-model-item prop='param3' style='width:150px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param3"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div>个购物券</div>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class='flex-a-c'>
          <a-form-model-item>
            <div>2、单笔订单实付金额满</div>
          </a-form-model-item>
          <a-form-model-item prop='param41' style='width:150px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param41"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div>元时,每消费</div>
          </a-form-model-item>
          <a-form-model-item prop='param42' style='width:150px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param42"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div>元,可得</div>
          </a-form-model-item>
          <a-form-model-item prop='param43' style='width:150px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param43"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div>个购物券</div>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class='flex-a-c'>
          <a-form-model-item>
            <div>3、签到1天送</div>
          </a-form-model-item>
          <a-form-model-item prop='param71' style='width:120px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param71"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div>个购物券,连续签到2天送</div>
          </a-form-model-item>
          <a-form-model-item prop='param72' style='width:120px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param72"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div>个购物券,连续签到7天送</div>
          </a-form-model-item>
          <a-form-model-item prop='param77' style='width:120px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param77"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div>个购物券</div>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class='flex-a-c'>
          <a-form-model-item>
            <div>4、下级第一次购买产品后送直接上级</div>
          </a-form-model-item>
          <a-form-model-item prop='param5' style='width:150px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param5"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div> 个购物券</div>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div class='flex-a-c'>
          <a-form-model-item>
            <div>5、点击广告观看成功后，送</div>
          </a-form-model-item>
          <a-form-model-item prop='param6' style='width:150px;padding-left: 20px'>
            <a-input-number :precision="2" :min='0' style='width:100%;' v-model="form.param6"/>
          </a-form-model-item>
          <a-form-model-item style='padding-left: 20px'>
            <div> 个购物券</div>
          </a-form-model-item>
        </div>
      </a-form-model-item>
      <a-row type='flex' align='middle' justify="center">
        <a-button type="primary" :loading='submitLoading' @click="onSubmit">保存</a-button>
      </a-row>
    </a-form-model>
  </a-spin>
</template>
<script>
import { bean } from '@/api'
export default {
  name: 'ParameterForm',
  data: () => ({
    shopId: '',
    formLoading: false,
    submitLoading: false,
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
    form: {
      samePercent: 0,
    },
    rules: {
      param1: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param2: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param3: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param41: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param42: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param43: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param5: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param6: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param71: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param72: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
      param77: [
        { required: true, message: '请输入', trigger: 'blur' },
      ],
    }
  }),
  async mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      this.formLoading = true
      let { code, msg, data } = await bean.getParameter()
      this.formLoading = false
      if (code === '00000') {
        if (data != null) {
          this.form = data
        }
      } else {
        this.$message.error(msg || '网络错误，请重试')
      }
    },

    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.saveParameter()
        } else {
          return false
        }
      })
    },
    async saveParameter () {
      this.submitLoading = true
      let { code, data, msg } = await bean.saveParameter(this.form)
      this.submitLoading = false
      if (code === '00000') {
        if (this.form.paramId) {
          this.$message.success('修改成功')
        } else {
          this.form.paramId = data.paramId
          this.$message.success('创建成功')
        }
      } else {
        this.$message.error(msg || '创建失败')
      }
    },
  }
}
</script>
<style lang="less" scoped>
.flex-a-c {
  display: flex;
  align-items: center;
}
.sub-title {
  padding: 0 16px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
  line-height: 42px;
  background-color: #f3f6f8;
}
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
</style>
